<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <v-row justify="space-between" align="center" class="mb-4 pl-3 mt-2">
        <h2>
          {{ inputs.language === 'en' ? 'Experiment 5: Question 5' : 'Expérience 5: Question 5' }}
        </h2>
        <v-btn-toggle
          v-model="inputs.language"
          class="ml-2"
          size="small"
          dense="dense"
          rounded="rounded"
          mandatory
        >
          <v-btn :value="'en'">English</v-btn>
          <v-btn :value="'fr'">Français</v-btn>
        </v-btn-toggle>
      </v-row>

      <p class="mb-4">
        {{
          inputs.language === 'en'
            ? 'Correctly identify each of the labelled parts of the diagram below:'
            : 'Identifiez correctement chaque partie étiquetée de la diagramme ci-dessous :'
        }}
      </p>

      <p class="pl-12 mb-4">
        <v-img style="max-width: 420px" :src="imageName" />
      </p>

      <v-select
        v-for="imgLabel in imageLabels"
        v-model="inputs[imgLabel.value]"
        :items="inputs.language === 'en' ? items : itemsFr"
        item-text="text"
        item-value="value"
        :key="`option${imgLabel.value}`"
        :label="imgLabel.text"
        class="mb-1"
        style="width: 350px"
      >
        <template #item="{item}">
          <span class="no-text-transform" v-html="item.text" />
        </template>
        <template #selection="{item}">
          <span class="no-text-transform" v-html="item.text" />
        </template>
      </v-select>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';

export default {
  name: 'uOttawa1321Prelab5Q5',
  components: {CalculationInput, ChemicalLatex, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        labelA: null,
        labelB: null,
        labelC: null,
        labelD: null,
        labelE: null,
        labelF: null,
        labelG: null,
        labelH: null,
        labelJ: null,
        labelK: null,
        labelL: null,
        labelM: null,
        labelN: null,
      },
      items: [
        {text: '25 mL round bottom flask', value: '25mlRBF'},
        {text: '50 mL heating mantle', value: '50mlHeatingMantle'},
        {text: '3-finger clamp (universal clamp)', value: 'universalClamp'},
        {text: 'Condenser', value: 'condenser'},
        {text: 'Extension clamp (2 finger clamp)', value: 'extensionClamp'},
        {text: 'Heating mantle plugged into Variac', value: 'heatingMantlePlugged'},
        {text: 'Lab jack', value: 'labJack'},
        {text: 'Stir plate', value: 'stirPlate'},
        {text: 'Stir plate plugged into wall', value: 'stirPlatePlugged'},
        {text: 'Variac', value: 'variac'},
        {text: 'Variac plugged into wall', value: 'variacPlugged'},
        {text: 'Water in', value: 'waterIn'},
        {text: 'Water out', value: 'waterOut'},
      ],
      itemsFr: [
        {text: 'Agitateur magnétique', value: 'stirPlate'},
        {text: 'Agitateur magnétique branché au mur', value: 'stirPlatePlugged'},
        {text: 'Ballon de 25 mL', value: '25mlRBF'},
        {text: 'Chauffe ballon branché au Variac', value: 'heatingMantlePlugged'},
        {text: 'Chauffe ballon de 50 mL', value: '50mlHeatingMantle'},
        {text: "Entrée de l'eau", value: 'waterIn'},
        {text: 'Levier de laboratoire', value: 'labJack'},
        {text: 'Pince à 3 doigts (pince universelle)', value: 'universalClamp'},
        {text: "Pince d'extension (pince à 2 doigts)", value: 'extensionClamp'},
        {text: 'Refrigérant', value: 'condenser'},
        {text: "Sortie de l'eau", value: 'waterOut'},
        {text: 'Variac', value: 'variac'},
        {text: 'Variac branché au mur', value: 'variacPlugged'},
      ],
      imageLabels: [
        {text: 'A:', value: 'labelA'},
        {text: 'B:', value: 'labelB'},
        {text: 'C:', value: 'labelC'},
        {text: 'D:', value: 'labelD'},
        {text: 'E:', value: 'labelE'},
        {text: 'F:', value: 'labelF'},
        {text: 'G:', value: 'labelG'},
        {text: 'H:', value: 'labelH'},
        {text: 'J:', value: 'labelJ'},
        {text: 'K:', value: 'labelK'},
        {text: 'L:', value: 'labelL'},
        {text: 'M:', value: 'labelM'},
        {text: 'N:', value: 'labelN'},
      ],
    };
  },
  computed: {
    versionNumber() {
      return this.taskState.getValueBySymbol('versionNumber').content;
    },
    imageName() {
      return '/img/assignments/refluxSetup' + this.versionNumber.value + '.jpg';
    },
  },
};
</script>
<style scoped></style>
